export const projects = [
  {
    skills
  }
];

export const skills = [
  "Powershell",
  "Solidity",
  "Bash",
  "JavaScript: {React, Redux, Web3.js, Ethers.js}",
  "Azure CLI",
  "Node.js",
  "ARM",
  "HTML || CSS",
  "KQL || SQL",
  "Devops {Biceps, Terraform}",

];
