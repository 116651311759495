import React from "react";

export default function Project() {
  const projects = [
    {
      title: "Proficient in Disaster Recovery, Seamless Cloud Migrations, and Optimal Azure Environment Design",
      subtitle:
        "Cloud Solutions",
      description: [
        "Implementing robust strategies for business continuity, optimizing performance and scalability through seamless on-premises to cloud migration, and architecting secure and efficient environments tailored to specific business needs by harnessing Azure's powerful capabilities."
      ],
    },
    {
      title: "This project includes a smart contract that adheres to the ERC-721 standard for Non-Fungible Tokens",
      subtitle:
        "Cryptic Punks",
      description:
        "CrypticPunks is Showcasing Unique NFTs with Smart Contracts deployed on Polygon Mainnet, demonstrating expertise in ERC721 standards and the transformative power of blockchain technology for creating and trading non-fungible tokens (NFTs) across  networks.",
      link: "https://purple-fire-5542.on.fleek.co/",
      repo: "https://github.com/kodiasteerforth/Cryptic_Punks",
    },
    {
      title: "Decentralized Ethereum/Token Exchange",
      subtitle: "Decentralized Exchange",
      description:
        "Gained hands-on experience with Ethereum Blockchain, Solidity, ERC-20 standards.Built a React/Redux frontend for the exchange. Tested on Kovan network, deployed on Heroku.",
      link: "https://cryptic-token-exchange.herokuapp.com/",
      repo: "https://github.com/kodiasteerforth/blockchain-developer-bootcamp-",
    },
    {
      title: "This is an arbitrage-based Cryptocurrency trading bot",
      subtitle: "Trading Bot",
      description:
        "This trading bot utilizes blockchain-powered smart contracts and real-time opportunities on cryptocurrency exchanges to profit from price discrepancies, using flash loans to execute trades",
      repo: "https://github.com/kodiasteerforth/Trading-Bot-hardhat",
    },
  ];

  return (
    <section id="projects" className="text-gray-400 bg-black-font">
    <div className="container px-5 py-10 mx-auto text-center lg:px-40">
      <div className="flex flex-col w-full mb-20">
        <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-blue-400">
          FEATURED PROJECTS
        </h1>
      </div>
      <div className="flex flex-wrap -m-4">
        {projects.map((project) => (
          <a
            href={project.link}
            key={project.title}
            className="sm:w-1/2 w-100 p-4"
          >
            <div className="flex relative">
              <div className="px-8 py-10 relative z-10 w-full border-2 border-gray-800 bg-gray-900 opacity-100 hover:bg-gray-800">
                <h2 className="tracking-widest text-lg title-font font-medium text-blue-300 mb-3">
                  {project.subtitle}
                </h2>
                <h1 className="title-font text-sm font-medium text-white mb-1">
                  {project.title}
                </h1>
                <p className="leading-relaxed">{project.description}</p>
                <a
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="tracking-widest text-sm title-font font-medium text-blue-300 mb-1"
                >
                  View Project
                </a>
                <br />
                <a
                  href={project.repo}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-4 inline-flex text-white bg-blue-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg"
                >
                  View Code
                </a>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  </section>
  );
  }