import React from "react";


export default function About() {
    return (
        <section id="about">
      <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-blue-400">
           
            <br className="hidden lg:inline-block" /> Azure Cloud Engineer 
          </h1>
          <p className="mb-8 leading-relaxed">
          I am Steerforth Kodia an Azure Cloud Engineer specialized in designing and deploying secure, scalable cloud solutions in Microsoft Azure. With additional experience in blockchain development, I bring expertise in integrating distributed ledger technologies into cloud architectures. Here are examples of my key projects.
          </p>
          <div className="flex justify-center">
            <a
              href="#contact"
              className="inline-flex text-white bg-blue-400 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg">
              Lets Work Together
            </a>
            <a
              href="#projects"
              className="ml-4 inline-flex text-white bg-blue-400 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg">
              Checkout My Work
            </a>
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="object-cover object-center rounded-full"
            alt="Image"
            src="./Image/IMG_2079.jpeg"
          />
        </div>
      </div>
    </section>

    );
}