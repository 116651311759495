import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faTwitter,
  faLinkedin,
  faHashnode,
} from "@fortawesome/free-brands-svg-icons";
import "./Social.css";

export default function Social() {
  return (
    <div className="social-container">
      <div className="social">
        <a href="mailto:kodiasteerforth@gmail.com">
          <FontAwesomeIcon
            className="icon"
            icon={faEnvelope}
            color="#007bff"
            size="2x"
          />
        </a>
        <a href="https://github.com/kodiasteerforth">
          <FontAwesomeIcon
            className="icon"
            icon={faGithub}
            color="#007bff"
            size="2x"
          />
        </a>
        <a href="https://twitter.com/buggykodia">
          <FontAwesomeIcon
            className="icon"
            icon={faTwitter}
            color="#007bff"
            size="2x"
          />
        </a>
        <a href="https://www.linkedin.com/in/steerforth-kodia-07026412a/">
          <FontAwesomeIcon
            className="icon"
            icon={faLinkedin}
            color="#007bff"
            size="2x"
          />
        </a>
        <a href="https://hashnode.com/@TheRealMvp">
          <FontAwesomeIcon
            className="icon"
            icon={faHashnode}
            color="#007bff"
            size="2x"
          />
        </a>
      </div>
    </div>
  );
}
